/*--------------------------------------------------------------
# Header style
--------------------------------------------------------------*/

.order_loader {
	width: 100%;
	height: 100%;
	position: fixed;
	z-index: 9999999;
	background: rgba(0, 0, 0, 0.8);
	text-align: center;
	padding: 100px 20px;
	font-weight: bold;
	font-size: 18px;
	color: #fff;
	display: none;
}
.lds-ellipsis {
  display: inline-block;
  position: relative;
  width: 64px;
  height: 64px;
}
.lds-ellipsis div {
  position: absolute;
  top: 27px;
  width: 11px;
  height: 11px;
  border-radius: 50%;
  background: #fff;
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.lds-ellipsis div:nth-child(1) {
  left: 6px;
  animation: lds-ellipsis1 0.6s infinite;
}
.lds-ellipsis div:nth-child(2) {
  left: 6px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(3) {
  left: 26px;
  animation: lds-ellipsis2 0.6s infinite;
}
.lds-ellipsis div:nth-child(4) {
  left: 45px;
  animation: lds-ellipsis3 0.6s infinite;
}
@keyframes lds-ellipsis1 {
  0% {
    transform: scale(0);
  }
  100% {
    transform: scale(1);
  }
}
@keyframes lds-ellipsis3 {
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(0);
  }
}
@keyframes lds-ellipsis2 {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(19px, 0);
  }
}


.order_form {
	width: 100%;
	height: 100%;
	position: fixed;
	left: 0px;
	top: 0px;
	z-index: 99999;
	overflow-y: scroll; /* has to be scroll, not auto */
	background: rgba(0, 0, 0, 0.8);
	-webkit-overflow-scrolling: touch;
	display: none;

	.woocommerce-error {
		width: 100%;
		float: left;
	}
	.inner {
		width: 600px;
		padding: 20px 30px;
		background: #fff;
		top: 0px;
		left: 50%;
		margin-left: -300px;
		position: relative;
		margin-bottom: 50px;
		float: left;
		border-radius: 0px 0px 5px 5px;
		box-shadow: 0px 3px 10px rgba(0, 0, 0, 0.8);

		.coupon {
			display: none;
		}

		.button {
			margin: 10px 0px 20px 0px;
		}

		.close {
			font-size: 24px;
			opacity: 0.4;
			cursor: pointer;
			position: absolute;
			right: 50px;
			top: 40px;
			z-index: 10;

			&:hover {
				opacity: 0.8;
			}
		}

		.logo {
			background: url(../images/Logo-Premiumhealth-Black.png) center center no-repeat;
			width: 100%;
			height: 70px;
			padding: 0px 20px;
			background-size: auto 60%;
			float: left;
			margin-bottom: 10px;
			position: relative;
		}

		h4 {
			width: 100%;
			float: left;
			padding-bottom: 10px;
			margin-bottom: 10px;
			line-height: 26px;
			margin-top: 0px;
			text-align: center;
			border-bottom: 1px solid #eee;

			span {
				color: $color-secondary;
			}
		}

		.fields {
			width: 100%;

			.adresinfo {
				display: none;
			}

			.row_field {
				float: left;
				width: 100%;
				margin-bottom: 5px;
				position: relative;

				&.left {
					width: 50%;
					padding-right: 5px;
				}

				&.right {
					width: 50%;
					padding-left: 5px;
				}

				.label {
					font-size: 13px;
					font-weight: bold;

					sup {
						color: #f00;
					}
				}

				&.numberfield {
					width: 80px;
					padding-left: 5px;
				}

				&.addfield {
					width: calc(50% - 80px);
					padding-left: 10px;
				}

				input {
					width: 100%;
					float: left;
					border: 1px solid #cdcdcd;
					box-sizing: border-box;
					padding: 3px 10px;
					border-radius: 2px;
					box-shadow: inset 1px 2px 2px 0px rgba(0, 0, 0, 0.1);
					float: left;

					&.error {
						border: 1px solid #f00;
					}

					&.valid {
						border: 1px solid $color-secondary;
					}
				}
			}
		}

		label.error {
			display: none !important;
		}
	}

	.terms {
		font-size: 11px;
		line-height: 16px;

		a {
			color: $color-primary;
			text-decoration: underline;
		}
	}

	.step_2, .step_1 {
		width: 100%;
		float: left;
	}

	.step_2 {
		display: none;

		.left {
			width: 50%;
			float: left;
			padding-right: 20px;

			.cart_usps {
				width: 100%;
				float: left;
				margin-top: 20px;
				font-size: 13px;

				.usp {
					line-height: 18px;
					margin-bottom: 10px;
					width: 100%;
					float: left;

					.fas {
						float: left;
						width: 30px;
						text-align: center;
						font-size: 18px;
						color: $color-secondary;
					}

					span {
						float: left;
						width: calc(100% - 30px);
					}
				}
			}

			.cart {
				border: 1px solid #d2d2d2;
				padding: 10px;
				float: left;
				width: 100%;

				span {
					font-weight: bold;
					font-size: 14px;
					width: 100%;
					float: left;
					margin-bottom: 5px;
				}

				.cart_line {
					width: 100%;
					float: left;
					font-size: 12px;
					line-height: 16px;
					margin-bottom: 10px;

					.name {
						width: 80%;
						float: left;
					}

					.value {
						width: 20%;
						float: left;
					}

					&.total {
						font-weight: bold;
						padding-top: 10px;
						border-top: 1px solid #d2d2d2;
					}
				}
			}
		}

		.right {
			width: 50%;
			float: left;

			h4 {
				border-bottom: 0px;
				text-align: left;
				margin-bottom: 0px;
			}

			.payment_options {
				ul {
					list-style: none;
					padding: 0px;

					li {
						width: 100%;
						float: left;
						border-bottom: 1px solid #eee;
						padding: 5px 0px;
					}
				}

				.payment_box {
					select{
						width: 100%;
						float: left;
						height: 35px;
					}

					p {
						margin-bottom: 0px;
						font-size: 13px;
					}
				}
			}
		}

		.postnl {
			width: 100%;
			float: left;
			height: 1px;
			background: #eee;
			margin: 40px 0px;

			.logo {
				width: 90px;
				height: 75px;
				float: left;
				background: #fff url(../images/postnl.png) center center no-repeat;
				background-size: 100%;
				left: 50%;
				margin-top: -35px;
				position: relative;
				margin-left: -45px;
			}
		}
	}

	@media (max-width: 670px) {
		.inner {
			width: 100%;
			margin: 0px;
			left: 0px;
			padding: 20px;

			.close {
				right: 20px;
			}

			.logo {
				display: none;
			}

			h4 {
				margin-top: 20px;
				text-align: left;
				padding-right: 40px;
				font-size: 16px;
				line-height: 21px;
			}

			.step_2 .left, .step_2 .right {
		 		width: 100%;
		 		padding: 0px;
		 	}
		}
	}

	@media (max-width: 400px) {
		.inner {
			border-radius: 0px;

			.fields .row_field.left, .fields .row_field.right {
				width: 100%;
				padding: 0px;
			}

			.fields .row_field.addfield {
				width: calc(100% - 80px);
			}

			.fields .row_field.numberfield {
				padding-left: 0px;
			}
		}
	}
}

.content {
	width: 100%;
	float: left;
	padding: 75px 0px;

	&.gray {
		background: #f5f5f5;
	}
	
	.image {
		text-align: center;
	}

	img {
		max-width: 90%;
	}

	p {
		margin-bottom: 20px;
	}

	h2, h3, h4 {
		font-size: 32px;
		margin-top: 0px;

		span {
			color: $color-secondary;
		}
	}

	ul {
		margin: 10px;
		padding: 0px 0px 0px 10px;

		li {
			display: block;
		}
	}

	.steps {
		width: 100%;
		float: left;
		margin-top: 10px;
	}

	.button {
		float: left;
		width: auto;
	}

	.step {
		width: 100%;
		float: left;
		margin-bottom: 20px;

		.circle {
			width: 60px;
			height: 60px;
			border: 3px solid $color-secondary;
			border-radius: 50px;
			text-align: center;
			font-weight: bold;
			color: $color-secondary;
			line-height: 53px;
			float: left;
		}

		.content {
			padding: 3px 0px;
			width: calc(100% - 80px);
			float: left;
			margin: 0px 0px 0px 10px;

			.title {
				font-weight: bold;
				float: left;
				width: 100%;
				margin-bottom: -3px;
			}
		}
	}
}

.reviews {
	width: 100%;
	float: left;
	text-align: center;

	h3 {
		margin-bottom: 40px;
	}
	.reviews_slider {
		width: 100%;
		float: left;
		position: relative;

		.slick-arrow {
			position: absolute;
			top: 50%;
			font-size: 40px;
			margin-top: -16px;
			opacity: 0.5;

			&:hover {
				opacity: 0.9;
			}

			&.fa-chevron-right {
				right: 0px;
			}

			&.fa-chevron-left {
				left: 0px;
			}
		}
	}
	.review {
		width: 100%;
		float: left;

		.image {
			width: 100px;
			height: 100px;
			border-radius: 80px;
			overflow: hidden;
			display: inline-block;
			box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
		}

		.fas {
			color: #f6d151;
			margin: 1px;
		}	

		.name {
			font-size: 18px;
			font-weight: bold;
		}

		.text {
			width: 100%;
			padding: 0px 150px;
			margin-bottom: 30px;
		}
	}
}

.know_from {
	.review {
		.image {
			box-shadow: 0px 0px 0px rgba(0, 0, 0, 0);
			border-radius: 0px;
			height: auto;
			margin-bottom: 20px;

			img {
				max-width: 100%;
			}
		}

		.text {
			font-size: 24px;
			line-height: 42px;
		}
	}

	.logo_slider {
		width: 100%;
		float: left;
		margin-top: 30px;
	}

	.logo_slide {
		height: 95px;
		float: left;
		position: relative;
		margin-right: 20px;
		margin-bottom: 10px;

		img {
			max-height: 70%;
			max-width: 70%;
			width: auto;
			height: auto;
			position: absolute;
			top: 0;
			bottom: 0;
			left: 0;
			right: 0;
			margin: auto;
		}
	}
}

.faq_items {
	width: 100%;
	float: left;

	.faqs {
		width: 100%;
		float: left;

		.content {
			padding: 0px;
		}

		.faq {
			width: 100%;
			float: left;
			margin-bottom: 10px;

			.open {
				.text {
					display: block;
				}
			}

			.title {
				background: #fff;
				border-radius: 3px;
				box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.03);
				float: left;
				font-weight: bold;
				padding: 8px 40px 8px 10px;
				width: 100%;
				position: relative;
				cursor: pointer;

				&:hover {
					color: $color-secondary;
				}

				.fas {
					float: right;
					color: $color-secondary;
					top: 50%;
					font-size: 18px;
					margin-top: -10px;
					right: 10px;
					position: absolute;
				}
			}

			.text {
				display: none;
				padding: 10px;
				width: 100%;
				float: left;
			}
		}
	}
}

.mobile_fixed {
	position: fixed;
	top: 0px;
	left: 0px;
	right: 0px;
	width: 100%;
	z-index: 10000;
	border-radius: 0px;
	opacity: 0;
	margin-top: -50px;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.2);
	transition: all 0.5s ease;
	
	&.show {
		margin-top:0px;
		opacity: 1;
	}
}

/*--------------------------------------------------------------
# Responsive header style
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.reviews .review {
		.text {
			padding: 0px 50px;
		}
	}
}

@include breakpoint(md) {
	.content img {
		max-height: 250px;
		margin-bottom: 20px;
	}

	.content {
		padding: 35px 0px;
	}

	.content h2, .content h3, .content h4 {
		font-size: 24px;

		br {
			display: none;
		}
	}

	.content .button {
		width: 100%;
	}
}

@include breakpoint(sm) {
	.reviews .review {
		.text {
			padding: 0px 0px;
		}
	}

	.reviews .reviews_slider .slick-arrow {
	 	display: none !important;
	}

	.know_from .review .text br {
		display: none;
	}
}