/*--------------------------------------------------------------
# Footer style
--------------------------------------------------------------*/
.footer {
	width: 100%;
	float: left;
	padding: 20px;
	border-top: 1px solid #eee;

	.copy {
		float: left;
		font-family: Poppins-Regular;
		font-size: 13px;
	}

	ul {
		float: right;
		padding: 0px;
		margin: 0px;

		li {
			float: left;
			display: inline-block;
		}

		a {
			color: $color-primary;
			padding: 0px 10px;
		}
	}
}

.overlay_modal {
	width: 100%;
	height: 100%;
	box-sizing: border-box;
	padding: 50px 0px;
	background: rgba(0, 0, 0, 0.7);
	position: fixed;
	left: 0px;
	top: 0px;
	overflow-y: scroll; /* has to be scroll, not auto */
	-webkit-overflow-scrolling: touch;
	z-index: 9999999;
	display: none;

	.inner {
		max-width: 600px;
		margin: auto;
		background: #fff;
		box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.5);
		border-radius: 5px;
		padding: 30px;
		box-sizing: border-box;
		position: relative;

		h1, h2, h3, h4, h5, h6 {
			font-size: 26px;
			font-weight: bold;
			margin: 0px 0px 0px 0px;
			width: 100%;
		}

		.close {
			position: absolute;
			right: 20px;
			top: 10px;
			font-size: 32px;
			cursor: pointer;
			z-index: 999;

			&:hover {
				opacity: 0.8;
			}
		}
	}
}

/*--------------------------------------------------------------
# Responsive footer style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {
	.overlay_modal { 
		padding: 0px;

		.inner {
			width: 98%;
			margin: 10px 1%;
			float: left;
		}
	}
}

@include breakpoint(sm) {

}