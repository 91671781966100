/*--------------------------------------------------------------
# Header style
--------------------------------------------------------------*/
.hero {
	width: 100%;
	height: auto;
	margin-bottom: 120px;
	padding: 0px 0px;
	float: left;
	position: relative;

	.overlay {
		width: 100%;
		height: 100%;
		position: absolute;
		background: rgba(0, 0, 0, 0.3);
		z-index: 0;
	}

	.container {
		position: relative;
		z-index: 1;
	}

	.logo {
		background: #fff url(../images/Logo-Premiumhealth-Black.png) center center no-repeat;
		width: 240px;
		height: 50px;
		padding: 0px 20px;
		background-size: auto 60%;
		float: left;
		border-radius: 10px;
		margin-bottom: 60px;
	}

	.left {
		width: 600px;
		float: left;
		padding: 40px 0px 0px 0px;
		color: #fff;
		text-shadow: 0px 2px 1px rgba(0, 0, 0, 0.4);

		.usps_mobile {
			display: none;
		}

		h1 {
			width: 100%;
			float: left;
			font-size: 46px;
			margin:0px 0px 30px 0px;
		}

		.usp {
			width: 100%;
			margin-bottom: 15px;
			float: left;
			font-size: 18px;

			span {
				font-weight: bold;
			}

			.fa-check {
				text-shadow: 0px 0px 0px rgba(0,0,0, 0);
				width: 25px;
				text-align: center;
				line-height: 25px;
				font-size: 16px;
				height: 25px;
				float: left;
				margin-right: 5px;
				border-radius: 30px;
				background: $color-secondary;
			}
		}

		.labels {
			width: 100%;
			float: left;
			margin-top: 20px;
			margin-bottom: 10px;

			img {
				max-width: 100%;
				max-height: 70px;
			}
		}
	}

	.right {
		background: #fff;
		padding: 40px 40px 80px 40px;
		float: left;
		margin-bottom: -40px;
		border-radius: 0px 0px 4px 4px;
		width: 400px;
		box-shadow:0px 3px 10px rgba(0, 0, 0, 0.3);
		text-align: center;
		position: relative;

		.terms {
			bottom: -100px;
			height: 80px;
			left: 0px;
			width: 100%;
			line-height: 16px;
			font-size: 11px;
			opacity: 0.5;
			position: absolute;
		}

		img {
			max-width: 80%;
			display: inline-block;
			margin: 20px 0px;
		}
		
		h3, p {
			width: 100%;
			float: left;
			margin: 0px;
		}

		h3 {
			font-size: 28px;
			margin-bottom: 10px;

			span {
				color: $color-secondary;
			}
		}

	}
}

/*--------------------------------------------------------------
# Responsive header style
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.hero {
		.left {
			width: 400px;

			h1 {
				font-size: 32px;
			}
		}
	}
}

@include breakpoint(md) {
	.hero {
		.logo {
			left: 50%;
			margin-left: -120px;
			position: relative;
			height: 40px;
			margin-bottom: 30px;
		}
		.left {
			width: 100%;
			padding-top: 20px;
			margin: 0px 0px 20px 0px;

			.usps {
				display: none;
			}

			h1 {
				display: none;
			}

			.usps_mobile {
				display: block;
				width: 100%;
				float: left;
				text-align: center;

				.usp .fas {
					float: none;
				}
			}
		}

		.right {
			width: 100%;
			padding: 20px 10px;
			margin: 0px 0px -100px 0px;

			h3 {
				font-size: 24px;
			}

			img {
				max-height: 250px;
			}
		}
	}
}

@include breakpoint(sm) {

}