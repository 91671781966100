/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.content_page {
	width: 100%;
	float: left;

	h1, h2, h3, h4, h5, h6 {
		font-size: 21px;
		margin: 0px 0px 5px 0px;
	}

	p {
		margin-bottom: 20px;
	}

	ul {
		list-style-type: disc;
		padding-left: 20px;
		margin: 0px 0px 20px 0px;

		li {
			list-style-type: disc;
		}
	}
}

.content-text {
	h2, h3, h4, h5, h6 {
		font-size: 21px;
		margin: 0px 0px 5px 0px;
	}

	p {
		margin-bottom: 20px;
	}

	ul {
		list-style-type: disc;
		padding-left: 20px;
		margin: 0px 0px 20px 0px;

		li {
			list-style-type: disc;

			a {
				color: #82b541;
			}
		}
	}
}

.page_title {
	padding: 35px 0px;
	background: #f8fef6;
	width: 100%;
	text-align: center;

	h1 {
		text-transform: uppercase;
	}
}

.lab-product {
	width: 100%;
	padding: 50px 0px;

	&.gray {
		background: #f8fef6;
	}

	.block1-link:hover {
		color: #fff;
	}

	.image-wrap {
		text-align: center;

		img {
			max-width: 90%;
			height: auto;
		}
	}
}
/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {

}

@include breakpoint(sm) {

}