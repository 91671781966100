/*--------------------------------------------------------------
# Page style
--------------------------------------------------------------*/
.woocommerce .woocommerce-error .button, .woocommerce .woocommerce-info .button, .woocommerce .woocommerce-message .button, .woocommerce-page .woocommerce-error .button, .woocommerce-page .woocommerce-info .button, .woocommerce-page .woocommerce-message .button {
	width: auto;
}
.woocommerce-error {
	float: left;
}
.woocommerce .login_wrapper {
	padding: 40px;
	box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
	background: #fff;
	margin:50px auto;
	width: 400px;
	left: 50%;
	margin-left: -200px;
	position: relative;
	float: left;
	border-bottom: 3px solid $color-secondary;

	@media (max-width: 450px) {
		width: 98%;
		margin: 10px 1%;
		left: 0px;
		padding: 20px;
	}

	h2 {
		width: 100%;
		float: left;
		font-size: 21px;
		border-bottom: 1px solid #eee;
		padding-bottom: 10px;
	}
	
	.woocommerce-form-login {
		border: 0px;
		padding: 0px;
	}

	.logo {
		background: url(../images/Logo-Premiumhealth-Black.png) center center no-repeat;
		width: 100%;
		height: 70px;
		padding: 0px 20px;
		background-size: auto 60%;
		float: left;
		margin-bottom: 0px;
		position: relative;
	}
}

.myaccount {
	.container {
		padding: 40px;
		box-shadow: 0px 2px 3px rgba(0, 0, 0, 0.1);
		background: #fff;
		margin:50px auto;
		border-bottom: 3px solid $color-secondary;

		.logo {
			background: url(../images/Logo-Premiumhealth-Black.png) left top no-repeat;
			width: 100%;
			height: 50px;
			padding: 0px;
			background-size: auto 60%;
			float: left;
			margin-bottom: 20px;
			position: relative;
			border-bottom: 1px solid #eee;
		}

		.woocommerce-MyAccount-navigation {
			ul {
				padding: 0px;
				list-style: none;

				li {

					&.is-active {
						a {
							color: $color-secondary;
							background: lighten($color-secondary, 45);
						}
					}

					a {
						background: #eee;
						border-bottom: 1px solid darken(#eee, 5);
						padding: 10px 20px;
						display: block;
						color: $color-primary;
						font-weight: bold;
						text-decoration: none;

						&:hover {
							color: $color-secondary;
						}
					}

					&.woocommerce-MyAccount-navigation-link--customer-logout {
						a {
							color: #f00;
						}
					}
				}
			}
		}
	}
}

.woocommerce form .form-row input.input-text, .woocommerce form .form-row textarea {
	border: 1px solid #cdcdcd;
	box-sizing: border-box;
	padding: 5px 10px;
	border-radius: 2px;
	box-shadow: inset 1px 2px 2px 0px rgba(0, 0, 0, 0.1);
}


/*--------------------------------------------------------------
# Responsive page style
--------------------------------------------------------------*/
@include breakpoint(lg) {

}

@include breakpoint(md) {
	.myaccount .container {
		margin: 10px 0px;
		padding: 0px 20px 20px 20px;
	}
	.woocommerce-account .woocommerce-MyAccount-content, .woocommerce-account .woocommerce-MyAccount-navigation {
		width: 100%;
		float: left;
	}
}

@include breakpoint(sm) {

}