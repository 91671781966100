// Font weights
$light: 400;
$regular: 500;
$bold: 700;
$ultra_bold: 900;

// Base Font
$base-font-family: 'Open Sans', sans-serif;
$base-font-weight: $regular;
$base-font-size: 16px;
$font-title: 'Open Sans', sans-serif;

//base colors
$color-primary: #3a3a3a;
$color-secondary: #82b541;
$color-alt: #f58f30;
$color-white: #ffffff;

//link colors
$color-link: $color-secondary;
$color-link-dark: darken($color-secondary, 10);
$color-link-light: $color-white;

//text colors
$color-text: $color-primary;
$color-text-light: lighten($color-primary, 10);
$color-text-lightest: $color-white;

//footer colors
$footer-primary: #2b2b2b;
$footer-secondary: #232323;
$footer-color-link: #686969;
$footer-color-tekst: #686969;