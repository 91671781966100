/*--------------------------------------------------------------
# Header style
--------------------------------------------------------------*/
.woocommerce-order-pay .woocommerce {
	.shop_table {
		width: 100%;
		float: left;
	}

	.pay-left {
		width: calc(60% - 20px);
		float: left;
	}

	.pay-right {
		width: 40%;
		float: right;
	}

	h2 {
		font-size: 21px;
		width: 100%;
		float: left;
		margin-bottom: 10px;

		span {
			color: #ed960c;
		}
	}

	.details {
		width: 50%;
		float: left;
		padding-right: 20px;
	}

	.support {
		float: left;
		width: 50%;
		background: #f0f7e7;
		border-bottom: 3px solid #82b541;
		padding: 15px;
		line-height: 21px;
		font-size: 14px;

		&.mobile {
			display: none;
			margin-top: 10px;
		}

		h3 {
			margin: 0px 0px 5px 0px;
			line-height: 21px;
			font-size: 16px;
			width: 100%;
			float: left;
		}
	}

	.stock {
		width: 100%;
		float: left;
		font-weight: bold;
		color: #ed960c;
	}

	#payment {
		width: 100%;
		float: right;
		background: #eee;
		margin-bottom: 4px;

		.wc_payment_method {
			width: 100%;
			float: left;
			border-bottom: 1px solid darken(#eee, 5);
		}

		input {
			float: left;
			margin-top: 7px !important;
			margin-right: 5px;
		}

		label {
			float: left;

			span {
			 	float: left;
			}
		}

		.payment_box {
			width: 100%;
			float: left;
		}

		.button {
			background-color: #ff5300;
			color: #fff;
			border-color: #ff5300;
			padding: 15px 20px;
			width: 100%;
			float: left;
			text-transform: uppercase;
			font-weight: 700;
			border-radius: 5px;
			text-shadow: 0 2px 1px rgba(0,0,0,.1);
			cursor: pointer;
			text-align: center;
			transition: all .5s ease;
		}
	}
}

.pl.page-template-template-direct_sales-checkout .payment .payment__subtitle {
	font-size: 18px;
}

#add_payment_method #payment ul.payment_methods, .woocommerce-cart #payment ul.payment_methods, .woocommerce-checkout #payment ul.payment_methods {
	list-style: none !important;
}

#add_payment_method #payment ul.payment_methods li, .woocommerce-cart #payment ul.payment_methods li, .woocommerce-checkout #payment ul.payment_methods li {
	list-style: none !important;
}

body.de {
	.payment_method_mollie_wc_gateway_ideal, .payment_method_mollie_wc_gateway_bancontact, .payment_method_mollie_wc_gateway_sofort, .payment_method_mollie_wc_gateway_eps, .payment_method_mollie_wc_gateway_przelewy24 {
		display: none !important;
	}
}

/*--------------------------------------------------------------
# Responsive header style
--------------------------------------------------------------*/
@include breakpoint(lg) {
	.woocommerce-order-pay .woocommerce {
		.pay-left {
			width: 100%;
		}

		.pay-right {
			width: 100%;
		}
	}
}

@include breakpoint(md) {
	.woocommerce-order-pay .woocommerce {
		.details {
			width: 100%;
		}

		.support {
			display: none;
			width: 100%;
			margin-bottom: 20px;
		}

		.support.mobile {
			display: block;
		}
	}
}

@include breakpoint(sm) {

}