/*--------------------------------------------------------------
# Header style
--------------------------------------------------------------*/
.sahne-bestellen {
	.header {
		background: #fff;
		box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.1);
	}
	.header .header__text p {
		color: #da252c;
	}
	/* .alert .alert__text p {
	    font-size: 1rem;
	    line-height: 1.25rem;
	} */

	.main .main__inner::after {
	    background-image: url(../images/cbd-figure.png);
	    background-repeat: no-repeat;
	    -webkit-background-size: contain;
	    background-size: contain;
	    background-position: center;
	}

	.main .main__logo-background {
	    background-repeat: no-repeat;
	    background-position: center;
	    -webkit-background-size: contain;
	    background-size: contain;
	    background-image: url(../images/logo-creme.png);
	    margin-bottom: 20px;
	}

	.main {
		background-repeat: no-repeat;
		background-position: center;
		-webkit-background-size: cover;
		background-size: cover;
		background-image: url(../images/bg-cbd.jpg);
	}

	.main__alert {
		background: #fff;
	}

	.main__badges-background {
		display: none;
	}

	.main .main__title {
	    -webkit-box-flex: 1;
	    flex-shrink: 0;
	    -ms-flex: 0 0 100%;
	    flex: 0 0 100%;
	}

	.main .main__title-coloured {
		font-size: 26px !important;
		color: #fff !important;
	}

	.main .main__list {
		top: 15px;
	}

	.main .main__text-coloured {
		color: #fff;
	}

	.main .main__text {
		color: #fff;
		font-size: 1.375rem;
	}

	.main .main__icon-background {
		background-image: url(../images/icons/icon-check-circle-red.svg);
	}

	.main .main__icon-background {
		height: 1.5rem;
		width: 1.5rem;
	}

	.full-width-banner .full-width-banner__subtitle, .discover .discover__title {
		color: #da252c;
	}
	
	.full-width-banner .full-width-banner__title, .discover .discover__title-coloured {
		color: #043927;
	}

	.button.button--orange {
	    background-color: #da252c;
	    color: #fff;
	    border-color: #da252c;
	    box-shadow: 0 0 2px 0 rgba(218,37,44,.8) !important;
	}

	.button.button--orange:hover {
		background-color: darken(#da252c, 5);
	}

	.discover .discover__description p {
		color: #043927;
		text-align: justify;
	}

	.full-width-figure {
		background: #043927;
	}

	.discover {
		padding-bottom: 30px;
	}

	.benefits .benefits__image-background {
		background-size: 79%;
	}

	.benefits .benefits__name span {
		color: #da252c;
		width: 100%;
		float: left;
	}

	.benefits .benefits__title {
		color: #da252c;	
	}
	
	.benefits .benefits__title span {
		color: #043927;
	}

	.benefits .benefits__name, .benefits .benefits__description p {
		color: #043927;
	}

	.form-bottom {
		background-repeat: no-repeat;
		background-position: center;
		-webkit-background-size: cover;
		background-size: cover;
		background-image: url(../images/bg-cbd.jpg);
	}

	.form-bottom .form-bottom__title {
		color: #fff;
	}

	.form-bottom .form-bottom__text {
		color: #fff;
	}

	.form-bottom .form-bottom__icon-background {
		background-image: url(../images/icons/icon-check-circle-red.svg);
	}

	.header .header__logo-background {
		float: left;
		width: 12.5rem;
		height: 50px;
		background-image: url(../images/logo-creme-dark.png);
		-webkit-background-size: contain;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: left;
	}
}

.sahne-checkout {
	.products::before {
		background: #043927;
	}
	.header .header__logo-background {
		background-image: url(../images/logo-creme-dark.png);
		-webkit-background-size: contain;
		background-size: contain;
		background-repeat: no-repeat;
		background-position: center;
	}
	.products .products__arrow::before {
		border-color: transparent transparent transparent #043927;
	}

	.ds-header .header__inner {
	    padding: 6px 0px 0px 0px;
	}

	.ds-header .header__logo-background {
	    height: 3.1rem;
	}

	.product.product--selected .product__inner {
		border-color: #da252c;
	}

	.product.product--selected .product__inner::before {
	    background: #da252c;
	    opacity: 0.2;
	}

	.product.product--selected .timer {
		background: #da252c;
		color: #fff;
	}

	.button.button--green {
	    background-color: #da252c;
	    color: #fff;
	    border-color: #da252c;
	    box-shadow: 0 0 2px 0 rgba(218,37,44,.8) !important;
	}

	.button.button--green:hover {
		background-color: darken(#da252c, 5);
	}

	.button.button--dark-green {
		background-color: darken(#da252c, 5);
		border-color: darken(#da252c, 5);
	}

	.button.button--dark-green:hover {
		background-color: darken(#da252c, 10);
		border-color: darken(#da252c, 10);
	}

	.button.button--orange {
	    background-color: #da252c;
	    color: #fff;
	    border-color: #da252c;
	}

	.button.button--orange:hover {
	    background-color: lighten(#da252c, 5);
	    border-color: lighten(#da252c, 5);
	}

	.payment .payment__arrow::after {
    	border-color: transparent transparent transparent #043927;
	}

	.payment .payment__form {
	    background-color: #043927;
	}

	&.discount15 .discount_alert_box, &.Rabatt15 .discount_alert_box, &.Znizka15 .discount_alert_box, &.znizka15 .discount_alert_box {
	    background: #da252c;
	}
}

@media print, screen and (min-width: 48em) {
	.sahne-bestellen {
		.main .main__logo-background {
			height: 9.375rem;
			width: 14.75rem;
		}
	}
}